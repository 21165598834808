import React from "react";
import * as styles from "./index.module.scss";

export default function index({ type, link }) {
  return (
    <a href={link || `#`} target="_blank" rel="noreferrer">
      {type === "linkedin" && (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"/></svg>
      )}
      {type === "instagram" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 25 25"
        >
          <path
            className={styles.cls1}
            d="M12.5,25h0A12.5,12.5,0,0,1,0,12.5H0A12.5,12.5,0,0,1,12.5,0h0A12.5,12.5,0,0,1,25,12.5h0A12.5,12.5,0,0,1,12.5,25Z"
          />
          <g>
            <path
              className={styles.cls2}
              d="M12.5,6.88c1.83,0,2,0,2.77,0a3.79,3.79,0,0,1,1.27.24,2.27,2.27,0,0,1,1.3,1.3,3.79,3.79,0,0,1,.24,1.27c0,.72,0,.94,0,2.77s0,2,0,2.77a3.79,3.79,0,0,1-.24,1.27,2.27,2.27,0,0,1-1.3,1.3,3.79,3.79,0,0,1-1.27.24c-.72,0-.94,0-2.77,0s-2,0-2.77,0a3.79,3.79,0,0,1-1.27-.24,2.27,2.27,0,0,1-1.3-1.3,3.8,3.8,0,0,1-.24-1.27c0-.72,0-.94,0-2.77s0-2,0-2.77a3.8,3.8,0,0,1,.24-1.27,2.27,2.27,0,0,1,1.3-1.3,3.79,3.79,0,0,1,1.27-.24c.72,0,.94,0,2.77,0m0-1.23c-1.86,0-2.09,0-2.83,0A5,5,0,0,0,8,6,3.5,3.5,0,0,0,6,8a5,5,0,0,0-.32,1.66c0,.73,0,1,0,2.83s0,2.09,0,2.83A5,5,0,0,0,6,17a3.5,3.5,0,0,0,2,2,5,5,0,0,0,1.66.32c.73,0,1,0,2.83,0s2.09,0,2.83,0A5,5,0,0,0,17,19a3.5,3.5,0,0,0,2-2,5,5,0,0,0,.32-1.66c0-.73,0-1,0-2.83s0-2.09,0-2.83A5,5,0,0,0,19,8a3.5,3.5,0,0,0-2-2,5,5,0,0,0-1.66-.32c-.73,0-1,0-2.83,0"
            />
            <path
              className={styles.cls2}
              d="M12.5,9A3.52,3.52,0,1,0,16,12.5,3.52,3.52,0,0,0,12.5,9m0,5.8a2.28,2.28,0,1,1,2.28-2.28,2.28,2.28,0,0,1-2.28,2.28"
            />
            <path
              className={styles.cls2}
              d="M17,8.84A.82.82,0,1,1,16.16,8a.82.82,0,0,1,.82.82"
            />
          </g>
        </svg>
      )}
      {type === "facebook" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 25 25"
        >
          <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
        </svg>
      )}
      {type === "twitter" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 25 25"
        >
          <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
        </svg>
      )}
    </a>
  );
}
