// extracted by mini-css-extract-plugin
export var blue = "index-module--blue--e1v-H";
export var clickArea = "index-module--clickArea--M6gzW";
export var cls1 = "index-module--cls1--wGWtS";
export var cls2 = "index-module--cls2--JumFe";
export var cls3 = "index-module--cls3--h18aD";
export var graphics = "index-module--graphics--UMxSR";
export var green = "index-module--green--ZORaj";
export var hide = "index-module--hide--EskUy";
export var logo = "index-module--logo--blKFl";
export var morph = "index-module--morph--N+46T";