/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react";
import * as styles from "./index.module.scss";
import { TweenMax, Sine } from "gsap";

export default class index extends Component {
  constructor(props) {
    super(props);

    this.menuPart1 = null;
    this.menuPart2 = null;
    this.menuPart3 = null;
    this.hamburger = null;
    this.state = {
      hover: false
    };
  }
  componentDidUpdate() {
    const { status, modalIsOpenned } = this.props;

    modalIsOpenned
      ? TweenMax.to(this.hamburger, 0.3, {
          autoAlpha: 0,
          scale: 0,
          ease: Sine.easeOut
        })
      : TweenMax.to(this.hamburger, 0.3, {
          autoAlpha: 1,
          scale: 1,
          ease: Sine.easeOut
        });
    if (status) {
      document.getElementById("menuToCross1").beginElement();
      document.getElementById("menuToCross2").beginElement();
    }
    if (!status) {
      document.getElementById("menuToOriginal1").beginElement();
      document.getElementById("menuToOriginal2").beginElement();
    }
  }
  render() {
    const { handleClick, location, status } = this.props;
    const menuStatus = status ? styles.clicked : "";
    const notHome = location.pathname !== "/" ? styles.notHome : "";
    return (
      <div
        className={`${styles.menu} ${notHome} fixed w-16 cursor-pointer`}
        onClick={handleClick}
        ref={hamburger => (this.hamburger = hamburger)}
        role="button"
        aria-label="hamburger menu"
        tabIndex="0"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 49.28 53.83"
          ref={menuPart1 => (this.menuPart1 = menuPart1)}
          className={`${menuStatus} absolute top-0 left-0 w-full`}
        >
          <defs>
            <clipPath id="clip-menu">
              <rect
                className={styles.cls1}
                x="196.89"
                y="85.59"
                width="35.53"
                height="155.07"
              />
            </clipPath>
          </defs>
          <polygon
            className={`${styles.cls2} ${styles.up}`}
            points="42.6 10.2 4.92 10.2 0 5 42.6 5 42.6 10.2 41.81 11.46 38.95 8.75 42.55 5 49.28 11.46 41.81 11.46"
          >
            <animate
              id="menuToOriginal1"
              begin="indefinite"
              fill="freeze"
              attributeName="points"
              dur="300ms"
              to="42.6 10.2 4.92 10.2 0 5 42.6 5 42.6 10.2 41.81 11.46 38.95 8.75 42.55 5 49.28 11.46 41.81 11.46"
            />
            <animate
              id="menuToCross1"
              begin="indefinite"
              fill="freeze"
              attributeName="points"
              dur="300ms"
              to="49.28 10.2 0 10.2 0 5 49.28 5 49.28 10.2 49.28 10.2 49.28 10.2 49.28 5 49.28 10.2 49.28 10.2"
            />
          </polygon>
          <polygon
            ref={menuPart2 => (this.menuPart2 = menuPart2)}
            className={`${styles.cls2} ${styles.middle}`}
            points="42.6 23.89 4.92 23.89 0 18.69 42.6 18.69 42.6 23.89 41.81 25.14 38.95 22.44 42.55 18.69 49.28 25.14 41.81 25.14"
          />
          <polygon
            className={`${styles.cls2} ${styles.down}`}
            points="42.6 37.57 4.92 37.57 0 32.37 42.6 32.37 42.6 37.57 41.81 38.83 38.95 36.13 42.55 32.37 49.28 38.83 41.81 38.83"
          >
            <animate
              id="menuToOriginal2"
              begin="indefinite"
              fill="freeze"
              attributeName="points"
              dur="300ms"
              to="42.6 37.57 4.92 37.57 0 32.37 42.6 32.37 42.6 37.57 41.81 38.83 38.95 36.13 42.55 32.37 49.28 38.83 41.81 38.83"
            />
            <animate
              id="menuToCross2"
              begin="indefinite"
              fill="freeze"
              attributeName="points"
              dur="300ms"
              to="49.28 37.57 0 37.57 0 32.37 49.28 32.37 49.28 37.57 49.28 37.57 49.28 37.57 49.28 37.57 49.28 37.57 49.28 37.57"
            />
          </polygon>
        </svg>
      </div>
    );
  }
}
