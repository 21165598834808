/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from "react";
import { Link } from "gatsby";
import { TweenMax, Power2 } from "gsap";
import * as styles from "./index.module.scss";
import HiddenMenu from "./HiddenMenu";
import Hamburger from "./Hamburger";
import Logo from "../utils/Logo";
import loadStates from "../../hocs/loadStates";

class Navigation extends Component {
  constructor(props) {
    super(props);

    // Ref
    this.nav = null;
    this.logo = null;
    this.state = {
      menuTrigger: false
    };
  }
  componentDidMount() {
    const { location } = this.props;

    if (location.pathname === "/") {
      TweenMax.set(this.nav, { autoAlpha: 0 });
      TweenMax.set(this.logo, { autoAlpha: 0 });
      setTimeout(() => {
        TweenMax.to(this.nav, 1, { autoAlpha: 1, ease: Power2.easeOut });
        TweenMax.to(this.logo, 1, { autoAlpha: 1, ease: Power2.easeOut });
      }, 8200);
    } else {
      TweenMax.set(this.nav, { autoAlpha: 1 });
      TweenMax.set(this.logo, { autoAlpha: 1 });
    }
  }
  componentDidUpdate() {
    TweenMax.set(this.nav, { autoAlpha: 1 });
    TweenMax.set(this.logo, { autoAlpha: 1 });
  }
  handleClick = () => {
    if (this.state.menuTrigger === false) {
      this.setState({ menuTrigger: true });
    } else {
      this.setState({ menuTrigger: false });
    }
  };
  closeMenu = () => {
    if (this.state.menuTrigger) {
      this.setState({ menuTrigger: false });
    }
  };
  render() {
    const { location, postPage } = this.props;
    const { isOpenned } = this.props.modal;
    return (
      <>
        <nav className={styles.nav} ref={nav => (this.nav = nav)}>
          {this.state.menuTrigger && (
            <div className={styles.clickArea} onClick={this.closeMenu} role="button" aria-label="close menu button" tabIndex="0"/>
          )}
          <div
            className={`${styles.logo} fixed z-20 cursor-pointer opacity-0`}
            ref={logo => (this.logo = logo)}
          >
            <Link to="/">
              <Logo color="black" />
            </Link>
          </div>
          <Hamburger
            handleClick={this.handleClick}
            location={location}
            status={this.state.menuTrigger}
            modalIsOpenned={isOpenned}
          />
          <HiddenMenu
            status={this.state.menuTrigger}
            closeMenu={() => this.closeMenu}
            location={location}
          />
        </nav>
        {location.pathname !== "/" ? (
          <div
            className={`${styles.graphics} ${
              location.pathname === "/our-approach" || postPage
                ? styles.hide
                : ""
            }`}
          />
        ) : (
          ""
        )}
      </>
    );
  }
}

export default loadStates(Navigation);
