import React, { Component } from "react";
import { Link } from "gatsby";
import * as styles from "./index.module.scss";
import FooterLogo from "../../images/logo/footer-logo.png";

export default class Footer extends Component {
  render() {
    let isHomePage, isAbsolute;
    if (this.props.location) {
      isHomePage =
        this.props.location.pathname === "/" ||
        this.props.location.pathname === "/our-approach" ||
        this.props.location.pathname === "/our-approach/"
          ? styles.home
          : "";
      isAbsolute =
        this.props.location.pathname === "/our-team" ? styles.absolute : "";
    } else {
      isHomePage = "";
    }

    return (
      <footer
        className={`${styles.footer} ${isHomePage} ${isAbsolute} relative w-full z-10 brand-white`}
      >
        <div className={styles.graphics}></div>
        <div className={styles.left}>© ALL RIGHTS RESERVED 2020</div>
        <div className={`${styles.logo} overflow-hidden`}>
          <Link to="/">
            <img src={FooterLogo} alt="" />
          </Link>
        </div>
        <ul
          className={`${styles.right} flex justify-end captions uppercase tracking-widest`}
        >
          <li>
            <Link to="/privacy">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/terms">Terms & Condition</Link>
          </li>
        </ul>
      </footer>
    );
  }
}

