import React from "react";
import * as styles from "./index.module.scss";

export default function Logo({ color }) {
  const logoColor =
    color === "black" ? styles.black : color === "yellow" ? styles.yellow : "";
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.58 32.35">
      <defs>
        <clipPath>
          <rect
            className={styles.cls1}
            x="8.25"
            y="448.66"
            width="29.74"
            height="93.83"
          />
        </clipPath>
      </defs>
      <polygon
        className={`${styles.cls3} ${logoColor}`}
        points="36.58 10.71 24.08 32.35 14.87 32.35 17.64 27.55 21.31 27.55 31.03 10.71 36.58 10.71"
      />
      <polygon
        className={`${styles.cls3} ${logoColor}`}
        points="6.98 0 31.97 0 36.58 7.98 31.03 7.98 29.2 4.8 9.75 4.8 6.98 0"
      />
      <polygon
        className={`${styles.cls3} ${logoColor}`}
        points="12.49 31.06 0 9.41 4.61 1.44 7.38 6.24 5.54 9.42 15.27 26.26 12.49 31.06"
      />
    </svg>
  );
}
