/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react";
import * as styles from "./index.module.scss";
import { Link } from "gatsby";
import { TimelineMax, Sine } from "gsap";
import FooterLogo from "../../../images/logo/footer-logo.png";

export default class index extends Component {
  constructor(props) {
    super(props);

    // Ref
    this.list = null;
    this.item1 = null;
    this.item2 = null;
    this.item3 = null;
    this.item4 = null;
    this.item5 = null;
    this.item6 = null;
    this.container = null;
    this.logo = null;
    this.tl = null;
  }
  componentDidMount() {
    this.tl = new TimelineMax({ paused: true })
      .set(this.container, {
        zIndex: 2000,
        onComplete: () => {
          if (this.props.status) {
            this.container.classList.add(styles.active);
          }
          if (this.props.status === false) {
            this.container.classList.remove(styles.active);
          }
        }
      })
      .fromTo(
        this.item1,
        0.7,
        { autoAlpha: 0, y: 20 },
        { autoAlpha: 1, y: 0, ease: Sine.easeOut },
        "+=0.5"
      )
      .fromTo(
        this.item2,
        0.7,
        { autoAlpha: 0, y: 20 },
        { autoAlpha: 1, y: 0, ease: Sine.easeOut },
        "-=.6"
      )
      .fromTo(
        this.item3,
        0.7,
        { autoAlpha: 0, y: 20 },
        { autoAlpha: 1, y: 0, ease: Sine.easeOut },
        "-=.6"
      )
      .fromTo(
        this.item4,
        0.7,
        { autoAlpha: 0, y: 20 },
        { autoAlpha: 1, y: 0, ease: Sine.easeOut },
        "-=.6"
      )
      .fromTo(
        this.item5,
        0.7,
        { autoAlpha: 0, y: 20 },
        { autoAlpha: 1, y: 0, ease: Sine.easeOut },
        "-=.6"
      )
      .fromTo(
        this.item6,
        0.7,
        { autoAlpha: 0, y: 20 },
        { autoAlpha: 1, y: 0, ease: Sine.easeOut },
        "-=.6"
      )
      .fromTo(
        this.logo,
        0.7,
        { autoAlpha: 0 },
        { autoAlpha: 1, ease: Sine.easeOut },
        "-=.4"
      );
  }

  componentDidUpdate() {
    if (this.props.status) {
      this.tl.play().timeScale(0.9);
    }
    if (this.props.status === false) {
      this.tl.reverse().timeScale(1.5);
    }
  }
  render() {
    const active = this.props.status ? styles.active : "";
    const close = this.props.status === false ? styles.close : "";
    const { closeMenu, location } = this.props;
    return (
      <div
        className={`${styles.container} ${active} ${close} fixed top-0 right-0 h-full flex`}
        ref={container => (this.container = container)}
      >
        <ul
          className={`${styles.list} self-center ml-16`}
          ref={list => (this.list = list)}
        >
          <li
            className={styles.item}
            ref={item => (this.item1 = item)}
            onClick={location.pathname === "/portfolio" ? closeMenu() : null}
          >
            <Link to="/portfolio">
              <span className="py-6 font-bold tracking-wide uppercase whitespace-no-wrap large-text font-title">
                Portfolio
              </span>
            </Link>
          </li>
          <li
            className={styles.item}
            ref={item => (this.item2 = item)}
            onClick={location.pathname === "/our-team" ? closeMenu() : null}
          >
            <Link to="/our-team">
              <span className="py-6 font-bold tracking-wide uppercase whitespace-no-wrap large-text font-title">
                Our Team
              </span>
            </Link>
          </li>
          <li
            className={styles.item}
            ref={item => (this.item3 = item)}
            onClick={location.pathname === "/our-approach" ? closeMenu() : null}
          >
            <Link to="/our-approach">
              <span className="py-6 font-bold tracking-wide uppercase whitespace-no-wrap large-text font-title">
                Our Approach
              </span>
            </Link>
          </li>
          <li
            className={styles.item}
            ref={item => (this.item4 = item)}
            onClick={location.pathname === "/news" ? closeMenu() : null}
          >
            <Link to="/news">
              <span className="py-6 font-bold tracking-wide uppercase whitespace-no-wrap large-text font-title">
                News / Media
              </span>
            </Link>
          </li>
          <li
            className={styles.item}
            ref={item => (this.item5 = item)}
            onClick={location.pathname === "/ecosystem" ? closeMenu() : null}
          >
            <Link to="/ecosystem">
              <span className="py-6 font-bold tracking-wide uppercase whitespace-no-wrap large-text font-title">
                Ecosystem
              </span>
            </Link>
          </li>
          <li
            className={styles.item}
            ref={item => (this.item6 = item)}
            onClick={location.pathname === "/contact" ? closeMenu() : null}
          >
            <Link to="/contact">
              <span className="py-6 font-bold tracking-wide uppercase whitespace-no-wrap large-text font-title">
                Contact Us
              </span>
            </Link>
          </li>
        </ul>
        <Link to="/" onClick={location.pathname === "/" ? closeMenu() : null}>
          <div
            className={`${styles.bottomLogo} absolute bottom-0 right-0 mr-10 mb-10 xxl:mr-16 xxl:mb-16`}
            ref={logo => (this.logo = logo)}
          >
            <img className="w-full" src={FooterLogo} alt="" />
          </div>
        </Link>
      </div>
    );
  }
}
