import React, { Component } from "react";
import { TweenMax, Sine } from "gsap";
import * as styles from "./index.module.scss";
import loadStates from "../../hocs/loadStates";

class Sidebar extends Component {
  constructor(props) {
    super(props);

    // Ref
    this.sidebar = null;
  }

  componentDidUpdate() {
    if (this.props.modal.isOpenned) {
      TweenMax.to(this.sidebar, 0.5, { autoAlpha: 0, ease: Sine.easeOut });
    }
    if (this.props.modal.isOpenned === false) {
      TweenMax.to(this.sidebar, 0.5, { autoAlpha: 1, ease: Sine.easeOut });
    }
  }

  render() {
    const { postPage } = this.props;
    return (
      <aside
        className={`${styles.sidebar} ${
          postPage ? "hidden" : ""
        } fixed w-16 z-20 h-1/2 lg:hidden`}
        ref={sidebar => (this.sidebar = sidebar)}
      >
        <ul className={`flex flex-col p-0 m-0 h-full justify-center`}>
          <li className={`self-center m-0 p-0 w-10 mb-6 ${styles.facebook}`}>
            <a
              href="https://www.facebook.com/vectr.ventures/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
                <path
                  className="fill-brand-black"
                  d="M12.5,25h0A12.5,12.5,0,0,1,0,12.5H0A12.5,12.5,0,0,1,12.5,0h0A12.5,12.5,0,0,1,25,12.5h0A12.5,12.5,0,0,1,12.5,25Z"
                />
                <path
                  className="fill-brand-white"
                  d="M10.6,20.06h3V12.44h2.12L16,9.88H13.64V8.43c0-.6.12-.84.7-.84H16V4.94H13.88c-2.26,0-3.29,1-3.29,2.91v2H9v2.58H10.6Z"
                />
              </svg>
            </a>
          </li>
          <li className={`self-center m-0 p-0 w-10 mb-6 ${styles.twitter}`}>
            <a href="https://twitter.com/vectr_ventures/"
               target="_blank"
               rel="noopener noreferrer">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
                <path
                  className="fill-brand-black"
                  d="M12.5,25h0A12.5,12.5,0,0,1,0,12.5H0A12.5,12.5,0,0,1,12.5,0h0A12.5,12.5,0,0,1,25,12.5h0A12.5,12.5,0,0,1,12.5,25Z"
                />
                <path
                  className="fill-brand-white"
                  d="M5.84,16.68a7.77,7.77,0,0,0,12-6.9,5.54,5.54,0,0,0,1.36-1.41,5.46,5.46,0,0,1-1.57.43,2.74,2.74,0,0,0,1.2-1.51A5.48,5.48,0,0,1,17.06,8a2.73,2.73,0,0,0-4.66,2.49A7.76,7.76,0,0,1,6.77,7.59a2.74,2.74,0,0,0,.85,3.65,2.72,2.72,0,0,1-1.24-.34,2.74,2.74,0,0,0,2.19,2.71,2.74,2.74,0,0,1-1.23,0,2.73,2.73,0,0,0,2.55,1.9A5.49,5.49,0,0,1,5.84,16.68Z"
                />
              </svg>
            </a>
          </li>
          <li className={`self-center m-0 p-0 w-10 mb-6 ${styles.linkedin}`}>
            <a
              href="https://www.linkedin.com/company/vectrventures/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
                <path
                  className="fill-brand-black"
                  d="M12.5,25h0A12.5,12.5,0,0,1,0,12.5H0A12.5,12.5,0,0,1,12.5,0h0A12.5,12.5,0,0,1,25,12.5h0A12.5,12.5,0,0,1,12.5,25Z"
                />
                <g id="_Group_" data-name="&lt;Group&gt;">
                  <g id="_Group_2" data-name="&lt;Group&gt;">
                    <rect
                      id="_Path_2"
                      data-name="&lt;Path&gt;"
                      className="fill-brand-white"
                      x="6.83"
                      y="9.71"
                      width="2.59"
                      height="8.36"
                    />
                    <ellipse
                      id="_Path_3"
                      data-name="&lt;Path&gt;"
                      className="fill-brand-white"
                      cx="8.11"
                      cy="7.08"
                      rx="1.53"
                      ry="1.54"
                    />
                  </g>
                  <path
                    id="_Path_4"
                    data-name="&lt;Path&gt;"
                    className="fill-brand-white"
                    d="M13.55,13.68c0-1.17.54-1.87,1.58-1.87s1.41.67,1.41,1.87v4.39h2.57V12.78c0-2.24-1.27-3.32-3-3.32a2.91,2.91,0,0,0-2.52,1.38V9.71H11.07v8.36h2.48Z"
                  />
                </g>
              </svg>
            </a>
          </li>
          <li className={`self-center m-0 p-0 w-10 mb-6 ${styles.instagram}`}>
            <a
              href="https://www.instagram.com/vectr_ventures/?hl=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
                <path
                  id="_Path_"
                  data-name="&lt;Path&gt;"
                  className="fill-brand-black"
                  d="M12.5,25h0A12.5,12.5,0,0,1,0,12.5H0A12.5,12.5,0,0,1,12.5,0h0A12.5,12.5,0,0,1,25,12.5h0A12.5,12.5,0,0,1,12.5,25Z"
                />
                <g id="_Group_" data-name="&lt;Group&gt;">
                  <path
                    id="_Compound_Path_"
                    data-name="&lt;Compound Path&gt;"
                    className="fill-brand-white"
                    d="M12.5,6.88c1.83,0,2,0,2.77,0a3.79,3.79,0,0,1,1.27.24,2.27,2.27,0,0,1,1.3,1.3,3.79,3.79,0,0,1,.24,1.27c0,.72,0,.94,0,2.77s0,2,0,2.77a3.79,3.79,0,0,1-.24,1.27,2.27,2.27,0,0,1-1.3,1.3,3.79,3.79,0,0,1-1.27.24c-.72,0-.94,0-2.77,0s-2,0-2.77,0a3.79,3.79,0,0,1-1.27-.24,2.27,2.27,0,0,1-1.3-1.3,3.8,3.8,0,0,1-.24-1.27c0-.72,0-.94,0-2.77s0-2,0-2.77a3.8,3.8,0,0,1,.24-1.27,2.27,2.27,0,0,1,1.3-1.3,3.79,3.79,0,0,1,1.27-.24c.72,0,.94,0,2.77,0m0-1.23c-1.86,0-2.09,0-2.83,0A5,5,0,0,0,8,6,3.5,3.5,0,0,0,6,8a5,5,0,0,0-.32,1.66c0,.73,0,1,0,2.83s0,2.09,0,2.83A5,5,0,0,0,6,17a3.5,3.5,0,0,0,2,2,5,5,0,0,0,1.66.32c.73,0,1,0,2.83,0s2.09,0,2.83,0A5,5,0,0,0,17,19a3.5,3.5,0,0,0,2-2,5,5,0,0,0,.32-1.66c0-.73,0-1,0-2.83s0-2.09,0-2.83A5,5,0,0,0,19,8a3.5,3.5,0,0,0-2-2,5,5,0,0,0-1.66-.32c-.73,0-1,0-2.83,0"
                  />
                  <path
                    id="_Compound_Path_2"
                    data-name="&lt;Compound Path&gt;"
                    className="fill-brand-white"
                    d="M12.5,9A3.52,3.52,0,1,0,16,12.5,3.52,3.52,0,0,0,12.5,9m0,5.8a2.28,2.28,0,1,1,2.28-2.28,2.28,2.28,0,0,1-2.28,2.28"
                  />
                  <path
                    id="_Path_2"
                    data-name="&lt;Path&gt;"
                    className="fill-brand-white"
                    d="M17,8.84A.82.82,0,1,1,16.16,8a.82.82,0,0,1,.82.82"
                  />
                </g>
              </svg>
            </a>
          </li>
          <li className={`self-center m-0 p-0 w-10 ${styles.socialPeace}`}>
            <a
              href="https://angel.co/company/vectr-ventures"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
                <defs>
                  <clipPath id="clip-socialPeace">
                    <rect
                      className="fill-brand-black"
                      x="0.22"
                      y="536.3"
                      width="29.74"
                      height="93.83"
                    />
                  </clipPath>
                </defs>
                <path
                  id="_Path_"
                  data-name="&lt;Path&gt;"
                  className="fill-brand-black"
                  d="M12.5,25h0A12.5,12.5,0,0,1,0,12.5H0A12.5,12.5,0,0,1,12.5,0h0A12.5,12.5,0,0,1,25,12.5h0A12.5,12.5,0,0,1,12.5,25Z"
                />
                <path
                  className="fill-brand-white"
                  d="M14.8,3.42a1.49,1.49,0,0,1,1,1.78l-1.61,5.91a.42.42,0,0,1,.12.2,1.51,1.51,0,0,1,1.41-.12,1.49,1.49,0,0,1,.59,1.71,1,1,0,0,1,.81.24,1.64,1.64,0,0,1,.33,1.55c0,1.84-.23,3.62-1.68,5V21a.42.42,0,0,1-.26.38c-1.55.6-3.4,0-4.82-.18a.43.43,0,0,1-.36-.41c0-.51.17-1,.2-1.55a8.38,8.38,0,0,1-1.85-2.94A4.29,4.29,0,0,1,9,13.59v-2L7.59,6.27a1.56,1.56,0,0,1,.89-2,1.51,1.51,0,0,1,1.78,1.1L11.54,9.2l1.52-4.64A1.46,1.46,0,0,1,14.8,3.42Zm-.94,1.37q-1,2.93-1.92,5.86a.42.42,0,0,1-.79,0l-1.67-5c-.16-.37-.41-.71-.74-.6s-.47.59-.36,1l1.46,5.43a.41.41,0,0,1,0,.11v1a11.63,11.63,0,0,1,.78-1,.42.42,0,0,1,.24-.12l2.38-.34L14.94,5a.59.59,0,0,0-.38-.77c-.39-.09-.61.27-.7.57Zm.33,7.64a28.9,28.9,0,0,0-.76,2.79c-.06.33-.14.61.14.72s.58-.12.75-.39c.38-.77.68-1.42,1-2.22.24-.52.27-1.22-.06-1.4A1,1,0,0,0,14.19,12.43Zm-.85-.51-2.17.31c-.47.55-.92,1.17-1.34,1.72A3.12,3.12,0,0,0,9.43,16a9,9,0,0,0,1.82,2.79.42.42,0,0,1,.08.25,7.17,7.17,0,0,1-.16,1.32,10,10,0,0,0,3.72.22V19.5a.42.42,0,0,1,.15-.31,4,4,0,0,0,1.29-2.05,1.61,1.61,0,0,1-1.33.29,1.18,1.18,0,0,1-.75-.73,1.49,1.49,0,0,1-.92,0c-.84-.32-1-.94-.7-1.77.1-.37.19-.73.26-1a5.81,5.81,0,0,1-.72.28,4.43,4.43,0,0,1-.2,1.24.42.42,0,0,1-.82-.17,4.2,4.2,0,0,0,.2-1.33.43.43,0,0,1,.29-.43,3.24,3.24,0,0,0,1.26-.6,2.09,2.09,0,0,0,.42-1Zm2.69,2c-.36.68-.68,1.41-1,2-.11.29-.09.56.16.67s.58-.12.75-.39c.19-.57.48-1.16.63-1.72.08-.3.14-.61-.13-.78A.37.37,0,0,0,16,13.94Z"
                />
              </svg>
            </a>
          </li>
        </ul>
      </aside>
    );
  }
}

export default loadStates(Sidebar);
