import React from "react";
import Navigation from "./Navigation";
import SideBar from "./SideBar";
import Footer from "./Footer";
import Modal from "./utils/Modal";

const Layout = ({ children, location, postPage }) => {
  const isOurApproachPage = location.pathname === "/our-approach";
  return (
    <>
      <Navigation location={location} postPage={postPage && postPage} />
      <SideBar postPage={postPage && postPage} />
      <main className={isOurApproachPage ? "our-approach" : null}>{children}</main>
      <Footer location={location} />
      <Modal location={location} />
    </>
  );
};

export default Layout;
