// extracted by mini-css-extract-plugin
export var active = "index-module--active--8bhjD";
export var bgContainer = "index-module--bgContainer--zFTNd";
export var categories = "index-module--categories--k5NZo";
export var clickArea = "index-module--clickArea--cXoFy";
export var container = "index-module--container--h0odD";
export var cross = "index-module--cross--QGPQq";
export var modal = "index-module--modal--Rls0K";
export var modalContent = "index-module--modalContent--IOSzC";
export var text = "index-module--text--RIn0t";
export var wide = "index-module--wide--sllJp";