/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react";
import * as styles from "./index.module.scss";
import { connect } from "react-redux";
import SocialIcon from "../SocialIcon";
import { toggleModal } from "../../../state/actions";
import { GatsbyImage } from "gatsby-plugin-image";
import { HtmlParser } from "../helperFunctions";

class ModalContent extends Component {
  constructor() {
    super();

    this.container = null;
    this.cross = null;
    this.content = null;
    this.modal = null;
    this.tl = null;
    this.modalState = null;
  }

  componentDidMount() {
    const { openModal, location } = this.props;
    if (location.pathname !== "/our-team") {
      openModal(false);
    }
  }

  // Close Modal
  handleClick = () => {
    const { openModal } = this.props;
    openModal(false);
  };

  render() {
    const { content, isOpenned } = this.props.modal;
    let modalState = "";
    if (isOpenned === true) {
      modalState = styles.active;
    }

    const isWide = content.wide ? styles.wide : "";

    return (
      <div
        className={`${styles.container} ${
          isOpenned ? styles.active : ""
        } fixed top-0 left-0 w-full h-full opacity-0 invisible`}
        ref={(container) => (this.container = container)}
      >
        <div
          className={`${styles.clickArea} ${isWide}`}
          onClick={this.handleClick}
          role="button"
          aria-label="close modal clickable area"
          tabIndex="0"
        />
        <article
          className={`${styles.modal} ${modalState} ${isWide} absolute top-0 right-0 h-full bg-brand-white`}
          ref={(modal) => (this.modal = modal)}
        >
          <div
            className={`${styles.cross} absolute top-0 right-0 mr-12 mt-12 opacity-0 cursor-pointer w-10 h-10`}
            ref={(cross) => (this.cross = cross)}
            onClick={this.handleClick}
            role="button"
            aria-label="close modal button"
            tabIndex="0"
          />
          <div className={`${styles.bgContainer} w-full`}>
            {content.image && (
              <GatsbyImage
                className="w-full h-full"
                image={content.image.childImageSharp.gatsbyImageData}
                loading="eager"
                alt=""
              />
            )}
          </div>
          <span
            className={`${styles.categories} captions uppercase absolute tracking-widest opacity-0`}
          >
            {typeof content.categories === "object"
              ? content.categories &&
                content.categories.map(
                  (item, index) => (index ? ", " : "") + item
                )
              : content.categories}
          </span>
          <div
            className={`${styles.modalContent} overflow-x-hidden overflow-y-scroll ml-16 mr-16 mt-20 opacity-0`}
            ref={(content) => (this.content = content)}
          >
            <h5 className={`${styles.name} uppercase leading-none`}>
              {content.title && content.title}
            </h5>
            <span
              className={`${styles.jobTitle} captions my-2 block uppercase tracking-wide`}
            >
              {content.position && content.position}
            </span>
            {content.facebook || content.twitter || content.linkedin ? (
              <div className={`${styles.socialIcons} flex`}>
                {content.facebook && (
                  <div className={`${styles.socialIcon} w-6 mr-4`}>
                    <SocialIcon type="facebook" link={content.facebook} />
                  </div>
                )}
                {content.linkedin && (
                  <div className={`${styles.socialIcon} w-6 mr-4`}>
                    <SocialIcon type="linkedin" link={content.linkedin} />
                  </div>
                )}
                {content.twitter && (
                  <div className={`${styles.socialIcon} w-6`}>
                    <SocialIcon type="twitter" link={content.twitter} />
                  </div>
                )}
              </div>
            ) : (
              ""
            )}
            {isOpenned && (
              <div className={`${styles.text} md-container mt-8`}>
                {content.text && <HtmlParser source={content.text} />}
              </div>
            )}
          </div>
          <div className={styles.filter} />
        </article>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    openModal: (isOpenned) => dispatch(toggleModal(isOpenned)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalContent);
