import React from 'react';
import ReactMarkdown from 'react-markdown';

const isOutOfViewport = elem => {
	const bounding = elem.getBoundingClientRect();

	const out = {};
	out.top = bounding.top < 0;
	out.left = bounding.left < 0;
	out.bottom =
		bounding.bottom >
		(window.innerHeight || document.documentElement.clientHeight);
	out.right =
		bounding.right >
		(window.innerWidth || document.documentElement.clientWidth);
	out.any = out.top || out.left || out.bottom || out.right;
	out.all = out.top && out.left && out.bottom && out.right;

	return out;
};

export const detectIfNewsContainerTopIsOutOfVP = selector => {
	const elem = document.querySelector(selector);
	const isOut = isOutOfViewport(elem);
	return isOut;
};

export function HtmlParser({ source }) {
	return <ReactMarkdown children={source} escapeHtml={false}/>;
}

export const homeSections = {
	OPENING: 0,
	INTRO: 1,
	QUOTATION: 2,
	MAP: 3,
	PORTFOLIO: 4,
	NEWS: 5,
	CONTACT: 6
};

export const approachSections = {
	INTRO: 5,
	SECTION_TWO: 4,
	SECTION_THREE: 3,
	SECTION_FOUR: 2,
	SECTION_FIVE: 1,
	SECTION_SIX: 0
};
