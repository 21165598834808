import {
  SET_MODAL_CONTENT,
  TOGGLE_MODAL,
  FILTER_CATEGORY,
  CHANGE_FILTER_STATE
} from "./type";



// Modal
export const setModalContent = content => ({
  type: SET_MODAL_CONTENT,
  content
});
export const toggleModal = isOpenned => ({
  type: TOGGLE_MODAL,
  isOpenned
});

// Filter
export const filterCategory = category => ({
  type: FILTER_CATEGORY,
  category
});

export const changeFilterState = state => ({
  type: CHANGE_FILTER_STATE,
  state
});
